import { useEffect, useState } from 'react'

import { QuestionMarkIcon } from '@/assets/icons/icons.jsx'
import { useWindowSize } from '@/hooks/useWindowSize.jsx'

import ChatModal from './chat-modal/ChatModal.jsx'
import { StyledIcon, StyledPositionWrapper } from './styles.js'

const FloatingChat = () => {
    const [isModalOpen, setModalOpen] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const fullScreenDesktopMode = fullScreen || (isModalOpen && isMobile)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    return (
        <StyledPositionWrapper fullScreenDesktopMode={fullScreenDesktopMode}>
            {isModalOpen ? (
                <ChatModal
                    onClose={() => {
                        setModalOpen(false), setFullScreen(false)
                    }}
                    fullScreen={fullScreen}
                    setFullScreen={setFullScreen}
                    isMobile={isMobile}
                />
            ) : (
                <StyledIcon onClick={() => setModalOpen(true)}>
                    <QuestionMarkIcon />
                </StyledIcon>
            )}
        </StyledPositionWrapper>
    )
}

export default FloatingChat
