import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import {
    AlertIcon,
    StarsIcon,
    CopyIcon,
    DoneIcon,
    PlayIcon,
} from '@/assets/icons/icons'
import DotsLoading from '@/components/dots-loading/DotsLoading'
import { useDetermineCode } from '@/hooks/useDetermineCode'
import { NEW_LINE } from '@/utilities/constants'
import { getTextBlocksWithCode, copyTextToClipboard } from '@/utilities/helpers'

import {
    StyledAlertIcon,
    StyledCode,
    StyledContainerPlayIcon,
    StyledCopyButton,
    StyledLoadingMessageItem,
    StyledMessageItem,
    StyledMessageTemplateItem,
    StyledMessageTemplateText,
} from './styles'

export const RegularMessage = ({
    isMine,
    color,
    text = '',
    scrollToCb,
    isSmallSize,
    audio,
    setAudio,
    setAudioPlaying,
}) => {
    const checkedText = useDetermineCode(text)
    const [displayText, setDisplayText] = useState('')
    const [currentCharIndex, setCurrentCharIndex] = useState(0)
    const [isTypingStopped, setTypingStopped] = useState(false)
    const [copied, setCopied] = useState('')

    useEffect(() => {
        if (!isMine) {
            const typingInterval = setInterval(() => {
                if (currentCharIndex < checkedText.length) {
                    setDisplayText(
                        prevDisplayText =>
                            prevDisplayText + checkedText[currentCharIndex]
                    )
                    setCurrentCharIndex(prevIndex => prevIndex + 1)
                    scrollToCb()
                } else {
                    clearInterval(typingInterval)
                    setTypingStopped(true)
                }
            }, 20)
            return () => {
                clearInterval(typingInterval)
            }
        } else {
            setDisplayText(checkedText)
        }
    }, [currentCharIndex, checkedText])

    return (
        <StyledMessageItem
            isMine={isMine}
            color={color}
            isSmallSize={isSmallSize}
        >
            <StyledMessageTemplateText isSmallSize={isSmallSize} as={'div'}>
                {getTextBlocksWithCode(displayText)?.map((block, index) => {
                    return (
                        <StyledCode key={index} isCode={block?.isCode}>
                            {block?.isCode && isTypingStopped && (
                                <StyledCopyButton
                                    onClick={() => {
                                        copyTextToClipboard(block?.text)
                                        setCopied(block?.text)
                                    }}
                                >
                                    {copied === block?.text && <DoneIcon />}
                                    <CopyIcon />
                                </StyledCopyButton>
                            )}
                            {block?.text?.split(NEW_LINE).map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))}
                        </StyledCode>
                    )
                })}
                {!isMine && isTypingStopped && audio && (
                    <StyledContainerPlayIcon>
                        <PlayIcon
                            onClick={() => {
                                setAudio(audio)
                                setAudioPlaying(true)
                            }}
                        />
                    </StyledContainerPlayIcon>
                )}
            </StyledMessageTemplateText>
        </StyledMessageItem>
    )
}

export const ErrorMessage = ({ isSmallSize, message }) => {
    const theme = useTheme()
    return (
        <StyledMessageItem
            color={theme.palette.red}
            isMine={false}
            isSmallSize={isSmallSize}
        >
            <StyledAlertIcon isSmallSize={isSmallSize}>
                <AlertIcon stroke={theme.palette.white} />
            </StyledAlertIcon>

            <StyledMessageTemplateText isSmallSize={isSmallSize}>
                {message ||
                    'Something went wrong. If this issue persists please contact us.'}
            </StyledMessageTemplateText>
        </StyledMessageItem>
    )
}
export const LoadingMessage = ({ color, scrollToCb, isSmallSize, isMine }) => {
    useEffect(() => {
        scrollToCb()
    }, [])
    return (
        <StyledLoadingMessageItem
            color={color}
            isMine={isMine}
            isSmallSize={isSmallSize}
        >
            <DotsLoading />
        </StyledLoadingMessageItem>
    )
}
export const TemplateMessage = ({
    color,
    itemCallback,
    title,
    text,
    isSmallSize,
}) => {
    return (
        <StyledMessageTemplateItem
            color={color}
            onClick={itemCallback}
            isSmallSize={isSmallSize}
        >
            <StarsIcon />
            <div>
                <StyledMessageTemplateText
                    isBold={true}
                    isSmallSize={isSmallSize}
                >
                    {title}
                </StyledMessageTemplateText>
                <StyledMessageTemplateText isSmallSize={isSmallSize}>
                    {text}
                </StyledMessageTemplateText>
            </div>
        </StyledMessageTemplateItem>
    )
}

RegularMessage.propTypes = {
    isMine: PropTypes.bool,
    color: PropTypes.string,
    text: PropTypes.string,
    scrollToCb: PropTypes.func,
    isSmallSize: PropTypes.bool,
    audio: PropTypes.object,
    setAudio: PropTypes.func,
    setAudioPlaying: PropTypes.func,
    audioPlaying: PropTypes.bool,
}

LoadingMessage.propTypes = {
    color: PropTypes.string,
    scrollToCb: PropTypes.func,
    isSmallSize: PropTypes.bool,
    isMine: PropTypes.bool,
}

TemplateMessage.propTypes = {
    color: PropTypes.string,
    text: PropTypes.string,
    itemCallback: PropTypes.func,
    title: PropTypes.string,
    isSmallSize: PropTypes.bool,
}

ErrorMessage.propTypes = {
    isSmallSize: PropTypes.bool,
    message: PropTypes.string,
}
