import PropTypes from 'prop-types'

import RecordingWaveAnimation from '@/pages/home/chat-input/recordingWave/RecordingWaveAnimation.jsx'
import { StyledContainerRecord } from '@/pages/home/chat-input/recordingWave/styles.js'
import { StyledStopRecordingButton } from '@/pages/home/styles.js'

const Recording = ({ onClick }) => {
    return (
        <StyledContainerRecord>
            <RecordingWaveAnimation />
            <StyledStopRecordingButton onClick={onClick} />
        </StyledContainerRecord>
    )
}

export default Recording

Recording.propTypes = {
    onClick: PropTypes.func,
    recorderState: PropTypes.object,
}
