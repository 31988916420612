import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import Header from '@/components/header/Header.jsx'
import { useWindowSize } from '@/hooks/useWindowSize.jsx'

import { StyledBody } from './styles'

const PublicLayout = ({ withHeader }) => {
    const { height } = useWindowSize()

    return (
        <>
            {withHeader && <Header />}
            <StyledBody withHeader={withHeader} vh={height}>
                <Outlet />
            </StyledBody>
        </>
    )
}

export default PublicLayout

PublicLayout.propTypes = {
    withHeader: PropTypes.bool,
}
