import axios from 'axios'

import config from '../../config.js'

const axiosConfig = {
    headers: {
        'x-api-key': config.aiXplainApiKey,
        'content-type': 'application/json',
    },
}

// create axios custom instance with custom config
const axiosInstance = axios.create(axiosConfig)

export default axiosInstance
