import { useContext } from 'react'

import { AppContext } from '@/context/AppProvider.jsx'

const useAppState = () => {
    const { isChatReset, ...rest } = useContext(AppContext)

    return { isChatReset, ...rest }
}

export default useAppState
