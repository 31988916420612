import PropTypes from 'prop-types'

import Button from '@/components/button/Button'
import useAppState from '@/hooks/useAppState'

import { ChatIconDots, LogoIcon } from '../../assets/icons/icons'

import {
    StyledHeaderRoot,
    StyledHeaderLogo,
    StyledHeaderInfo,
    StyledHeaderWrapper,
} from './styles'

const Header = () => {
    const { user, resetChatAction } = useAppState()

    return (
        <StyledHeaderWrapper>
            <StyledHeaderRoot>
                <StyledHeaderLogo hasUser={!!user}>
                    <LogoIcon />
                    <ChatIconDots />
                </StyledHeaderLogo>
                <StyledHeaderInfo>
                    <Button
                        size={'large'}
                        variant={'yellow'}
                        onClick={() => resetChatAction(true)}
                    >
                        Start a new chat
                    </Button>
                </StyledHeaderInfo>
            </StyledHeaderRoot>
        </StyledHeaderWrapper>
    )
}

export default Header

Header.propTypes = {
    isMobile: PropTypes.bool,
}
