import { createBrowserRouter } from 'react-router-dom'

import PublicLayout from '@/layouts/public-layout/PublicLayout.jsx'
import * as pages from '@/pages'
import * as routeNames from '@/utilities/constants'

const router = createBrowserRouter(
    [
        {
            element: <PublicLayout withHeader />,
            children: [
                {
                    exact: true,
                    path: routeNames.ROUTE_HOME,
                    element: <pages.Home />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_FLOATING_CHAT,
                    element: <pages.FloatingChat />,
                },
            ],
        },
        {
            path: '*', // Not found route
            element: <pages.NotFound />,
        },
    ],
    {
        basename: '/',
    }
)

export default router
