import styled, { css } from 'styled-components'

export const StyledBaseButton = styled.button`
    ${({ ...props }) => {
        const { size, collapsed, variant, theme, floating } = props
        return css`
            border-radius: 12px;
            cursor: pointer;
            //children
            display: flex;
            align-items: center;
            justify-content: center;
            gap: ${theme.spacing}px;
            &:disabled {
                cursor: not-allowed;
            }
            ${getSize(size, collapsed)}
            ${getVariant(variant)}
            ${floating &&
            css`
                width: 100% !important;
                padding: 5px;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `}
        `
    }}
`

const getSize = (size, collapsed) => {
    switch (size) {
        case 'small':
            return css`
                height: 40px;
                width: ${collapsed ? '40px' : '100%'};
                font-size: 12px;
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `
        case 'medium':
            return css`
                height: 48px;
                width: ${collapsed ? '48px' : '100%'};
                font-size: 14px;
                padding: 4px 8px;
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `
        case 'large':
            return css`
                height: 56px;
                width: ${collapsed ? '56px' : '100%'};
                font-size: 18px;
                padding: 12px 24px;
                & svg {
                    width: 16px;
                    height: 16px;
                }
            `
        case 'fullWidth':
            return css`
                height: 48px;
                width: 100%;
                font-size: 14px;
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `
        default:
            return css`
                height: 40px;
                width: ${collapsed ? '40px' : '120px'};
                font-size: 12px;
                padding: 12px 24px;
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `
    }
}

const getVariant = variant => {
    switch (variant) {
        case 'dark':
            return DarkVariant
        case 'yellow':
            return YellowVariant
        default:
            return DarkVariant
    }
}

const DarkVariant = ({ theme }) => {
    return css`
        border: none;
        padding: ${theme.spacing * 1.5}px ${theme.spacing * 3}px;
        color: ${theme.palette.white};
        background-color: ${theme.palette.green};
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        width: 350px;
        &:hover {
            background-color: ${theme.palette.green};
        }
        &:disabled {
            cursor: pointer;
            border: 1px solid ${theme.palette.gray.light};
            padding: ${theme.spacing * 1.5}px ${theme.spacing * 3}px;
            color: ${theme.palette.gray.light};
            background-color: ${theme.palette.gray.dark};
        }
        ${theme.mediaQueries.mUp} {
            width: 380px;
        }
    `
}

const YellowVariant = ({ theme }) => {
    return css`
        color: ${theme.palette.white};
        background-color: ${theme.palette.yellowOpacity};
        border: 2px solid ${theme.palette.yellow};
        height: fit-content;
        font-weight: 600;
        line-height: 18px;
        &:hover {
            background-color: ${theme.palette.yellow};
        }
        white-space: nowrap;
    `
}
