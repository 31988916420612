import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import useAppState from '@/hooks/useAppState.jsx'
import { useWindowSize } from '@/hooks/useWindowSize.jsx'
import ChatBody from '@/pages/home/chat-body/ChatBody.jsx'
import ChatInput from '@/pages/home/chat-input/ChatInput.jsx'
import RecordingWaveAnimation from '@/pages/home/chat-input/recordingWave/RecordingWaveAnimation.jsx'
import { httpSendMessage } from '@/services/http-requests/chat.http'
import { ROLE_ASSISTANT, ROLE_USER } from '@/utilities/constants'
import {
    convertAudioFileToUrl,
    convertAudioUrlToText,
    convertStringIntoAudio,
    convertTextToAudio,
    handlePolling,
    scrollToCb,
} from '@/utilities/helpers'

import {
    StyledInputWrapper,
    StyledMessagesWrapper,
    StyledWrapper,
} from './styles'

const Home = ({ isSmallSize }) => {
    const { isChatReset, resetChatAction } = useAppState()
    const [messages, setMessages] = useState([])
    const [messageText, setMessageText] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [cancelToken, setCancelToken] = useState(null)

    const [audio, setAudio] = useState(null)
    const [audioPlaying, setAudioPlaying] = useState(false)
    const [isLoadingSendAudio, setIsLoadingSendAudio] = useState(false)

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const inputDisabled = isLoading || !messageText.trim()
    const isMicDisabled = audioPlaying || isLoading
    const MAX_TOKENS_NUMBER = 1000

    const messagesWrapper = useRef()

    useEffect(() => {
        if (messages.length || isLoading) {
            scrollToCb(messagesWrapper)
        }
    }, [messages.length, isLoading])

    useEffect(() => {
        if (isChatReset) {
            if (cancelToken) {
                cancelToken.cancel('Request canceled by user')
            }
            setMessages([])
            setMessageText('')
            if (messagesWrapper.current) {
                messagesWrapper.current.scrollTop = 0
            }
            resetChatAction(false)
        }
    }, [isChatReset])

    const sendAudio = async audioReceived => {
        setIsLoadingSendAudio(true)
        try {
            const audioURL = await convertAudioFileToUrl(audioReceived)
            const textToShow = await convertAudioUrlToText(audioURL)
            if (textToShow) {
                await triggerMessagePipeline(textToShow)
                window.URL.revokeObjectURL(audioReceived)
            }
        } catch (error) {
            console.log('err', error)
            setError(true)
        } finally {
            setIsLoadingSendAudio(false)
        }
    }

    const handleAudio = async text => {
        const audioFromAssistant = await convertTextToAudio(text)
        const audioToPlay = convertStringIntoAudio(audioFromAssistant)
        if (audioToPlay) {
            setAudio(audioToPlay)
            setAudioPlaying(true)
        }

        return audioToPlay
    }

    const addMessage = (message, role = ROLE_ASSISTANT, audioToPlay) => {
        setMessages(prevState => [
            ...prevState,
            {
                content: message,
                role,
                audio: audioToPlay,
            },
        ])
    }

    const getChatResponse = async url => {
        try {
            const message = await handlePolling(url)
            const audioToPlay = await handleAudio(message)

            if (audioToPlay) {
                addMessage(message, ROLE_ASSISTANT, audioToPlay)
            } else {
                addMessage(message, ROLE_ASSISTANT)
            }

            setIsLoadingSendAudio(false)
            setIsLoading(false)
        } catch (error) {
            setError(true)
            setIsLoadingSendAudio(false)
            setIsLoading(false)
        }
    }

    //send user message
    const triggerMessagePipeline = async messageToSend => {
        try {
            setIsLoading(true)
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setCancelToken(source)

            const messagesContext = [
                ...messages,
                {
                    content: messageToSend,
                    role: ROLE_USER,
                },
            ]

            const messagesContextWithoutAudio = messagesContext.map(message => {
                return {
                    content: message.content,
                    role: message.role,
                }
            })

            addMessage(messageToSend, ROLE_USER)

            const response = await httpSendMessage(
                messagesContextWithoutAudio,
                MAX_TOKENS_NUMBER,
                source.token
            )

            //this is the url to poll
            const url = response.data.data
            getChatResponse(url)
        } catch (error) {
            setError(true)
            console.error('Error get url:', error.message)
            setIsLoading(false)
        }
    }

    const handleSendMessage = (event, messageToSend) => {
        event.preventDefault()
        event.stopPropagation()
        if (error) {
            setError(false)
        }

        triggerMessagePipeline(messageToSend.trim())
        setMessageText('')
    }

    const toggleAudio = (stop = false) => {
        if (audioPlaying && !stop) {
            audio?.play()
        } else if (audioPlaying && stop) {
            audio?.pause()
            setAudio(null)
            setAudioPlaying(false)
        }
    }

    useEffect(() => {
        const handleAudioStop = () => {
            setAudioPlaying(false)
            setAudio(null)
        }

        if (audioPlaying && audio) {
            toggleAudio()
            audio?.addEventListener('ended', handleAudioStop)
        } else if (!audioPlaying && audio) {
            toggleAudio(true)
        }

        return () => {
            audio?.removeEventListener('ended', handleAudioStop)
        }
    }, [audio, audioPlaying])

    return (
        <>
            <RecordingWaveAnimation
                stopAudio={() => {
                    toggleAudio(true)
                }}
                isMobile={isMobile}
                haveOverlay
                appear={audioPlaying}
            />
            <StyledWrapper>
                <StyledMessagesWrapper
                    ref={messagesWrapper}
                    isSmallSize={isSmallSize}
                >
                    <ChatBody
                        messages={messages}
                        isLoading={isLoading}
                        isLoadingSendAudio={isLoadingSendAudio}
                        isSmallSize={isSmallSize}
                        handleSendMessage={handleSendMessage}
                        error={error}
                        errorMessage={errorMessage}
                        messagesWrapper={messagesWrapper}
                        setAudio={setAudio}
                        setAudioPlaying={setAudioPlaying}
                        audioPlaying={audioPlaying}
                    />
                </StyledMessagesWrapper>

                <StyledInputWrapper isSmallSize={isSmallSize}>
                    <ChatInput
                        inputDisabled={inputDisabled}
                        isMicDisabled={isMicDisabled}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        setIsLoading={setIsLoading}
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                        handleSendMessage={handleSendMessage}
                        isSmallSize={isSmallSize}
                        sendAudio={sendAudio}
                    />
                </StyledInputWrapper>
            </StyledWrapper>
        </>
    )
}

export default Home
Home.propTypes = {
    isSmallSize: PropTypes.bool,
}
