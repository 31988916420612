import { RouterProvider } from 'react-router-dom'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

import AppProvider from '@/context/AppProvider.jsx'
import { getAppTheme, GlobalStyles, ResetStyles } from '@/theme'

import router from './router.jsx'

function App() {
    const themeName = 'light'
    return (
        <ThemeProvider theme={getAppTheme({ name: themeName })}>
            <StyleSheetManager>
                <AppProvider>
                    <ResetStyles />
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </AppProvider>
            </StyleSheetManager>
        </ThemeProvider>
    )
}

export default App
