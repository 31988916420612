import styled, { css } from 'styled-components'

export const StyledBody = styled.div`
    ${({ theme, vh }) => css`
        position: relative;
        top: 80px;
        z-index: 1;
        width: 100%;
        height: calc(${vh}px - 80px);
        overflow-y: auto;
        background-color: ${theme.palette.gray.mainDark};
        padding: 10px;
        ${theme.mediaQueries.lUp} {
            padding: 0px;
        }
        ${theme.mediaQueries.xxlUp} {
            margin: 0 auto;
            max-width: 1024px;
        }
    `}
`
