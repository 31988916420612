export const MESSAGE_TEMPLATES = [
    {
        title: 'Design a database schema',
        text: 'for an online merch store',
        message: 'Design a database schema for an online merch store',
    },
    {
        title: 'Come up with concepts',
        text: 'for retro-style arcade game',
        message: 'Come up with concepts for retro-style arcade game',
    },
    {
        title: 'Show me a code snippet',
        text: 'for a website’s sticky header',
        message: 'Show me a code snippet for a website’s sticky header',
    },
    {
        title: 'Compare marketing strategies',
        text: 'for sunglasses for Gen Z and Millennials',
        message:
            'Compare marketing strategies for sunglasses for Gen Z and Millennial',
    },
    {
        title: 'Write a text message',
        text: 'asking a friend to be my plus-one at a wedding',
        message:
            'Write a text message asking a friend to be my plus-one at a wedding',
    },
]
