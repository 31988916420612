import styled, { css } from 'styled-components'

export const StyledPositionWrapper = styled.div`
    ${({ theme, fullScreenDesktopMode }) => css`
        position: fixed;
        right: 0;
        bottom: 80px;
        z-index: 9999;
        background-color: ${theme.palette.gray.mainDark};
        border-radius: 30px 0px 0px 30px;
        ${fullScreenDesktopMode &&
        css`
            bottom: 0;
            width: 100%;
            border-radius: 0px;
        `};
    `}
`

export const StyledIcon = styled.div`
    ${({ theme }) => css`
        width: 50px;
        height: 50px;
        border-radius: 30px 0px 0px 30px;
        background-color: ${theme.palette.gray.light};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & svg {
            & path {
                fill: ${theme.palette.gray.main};
            }
        }
    `}
`
