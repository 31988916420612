import PropTypes from 'prop-types'

import {
    StyledContainer,
    StyledContainerRound,
    StyledOverlay,
    StyledWaveContainer,
    Wave1,
    Wave2,
    Wave3,
    Wave4,
    Wave5,
} from '@/pages/home/chat-input/recordingWave/styles.js'

const RecordingWaveAnimation = ({
    haveOverlay = false,
    stopAudio,
    appear = false,
    isMobile,
}) => {
    if (haveOverlay) {
        return (
            <>
                {appear && (
                    <>
                        <StyledOverlay />
                        <StyledContainer
                            onClick={stopAudio}
                            isMobile={isMobile}
                            appear={appear}
                        >
                            <StyledContainerRound>
                                <StyledWaveContainer>
                                    <Wave1 />
                                    <Wave2 />
                                    <Wave3 />
                                    <Wave4 />
                                    <Wave5 />
                                </StyledWaveContainer>
                            </StyledContainerRound>
                        </StyledContainer>
                    </>
                )}
            </>
        )
    } else {
        return (
            <StyledWaveContainer>
                <Wave1 />
                <Wave2 />
                <Wave3 />
                <Wave4 />
                <Wave5 />
            </StyledWaveContainer>
        )
    }
}

export default RecordingWaveAnimation

RecordingWaveAnimation.propTypes = {
    haveOverlay: PropTypes.bool,
    stopAudio: PropTypes.func,
    appear: PropTypes.bool,
    isMobile: PropTypes.bool,
}
