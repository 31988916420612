import styled, { css } from 'styled-components'

export const StyledModalHeader = styled.div`
    ${({ theme, fullScreen }) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${theme.spacing}px;
        align-items: center;
        ${fullScreen &&
        css`
            height: 80px;
            ${theme.mediaQueries.lUp} {
                padding: 0 110px;
            }
            ${theme.mediaQueries.xxlUp} {
                padding: 0;
            }
        `};
    `}
`

export const StyledHeaderLogo = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing}px;
        & svg {
            height: 25px;
            width: auto;
        }
    `}
`
export const StyledHeaderInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing / 2}px;
        & svg {
            cursor: pointer;
        }
    `}
`

export const StyledCloseIcon = styled.span`
    ${({ theme }) => css`
        cursor: pointer;
        width: 100%;
        border-radius: 12px;
        cursor: pointer;
        background-color: ${theme.palette.gray.mainDark};
        border: 2px solid ${theme.palette.gray.veryDark};
        padding: 8px;
        height: 33px;
    `}
`
export const StyledFullscreenIcon = styled(StyledCloseIcon)`
    ${css`
        padding: 5px;
    `}
`
