import PropTypes from 'prop-types'

import {
    ChatIconDots,
    CloseIcon,
    ExpandIcon,
    FullscreenIcon,
    LogoIcon,
} from '@/assets/icons/icons.jsx'
import Button from '@/components/button/Button.jsx'
import useAppState from '@/hooks/useAppState.jsx'

import {
    StyledCloseIcon,
    StyledFullscreenIcon,
    StyledHeaderInfo,
    StyledHeaderLogo,
    StyledModalHeader,
} from './styles.js'

const ModalHeader = ({ onClose, fullScreen, setFullScreen, isMobile }) => {
    const { resetChatAction } = useAppState()

    return (
        <StyledModalHeader fullScreen={fullScreen}>
            <StyledHeaderLogo fullScreen={fullScreen}>
                <LogoIcon />
                <ChatIconDots />
            </StyledHeaderLogo>

            <StyledHeaderInfo>
                <Button
                    variant={'yellow'}
                    size={'medium'}
                    floating={!fullScreen}
                    onClick={() => resetChatAction(true)}
                >
                    {isMobile || !fullScreen ? (
                        <ChatIconDots />
                    ) : (
                        'Start a new chat'
                    )}
                </Button>

                {!isMobile && (
                    <StyledFullscreenIcon
                        onClick={() => setFullScreen(!fullScreen)}
                    >
                        {fullScreen ? <FullscreenIcon /> : <ExpandIcon />}
                    </StyledFullscreenIcon>
                )}
                <StyledCloseIcon onClick={onClose}>
                    <CloseIcon />
                </StyledCloseIcon>
            </StyledHeaderInfo>
        </StyledModalHeader>
    )
}

export default ModalHeader

ModalHeader.propTypes = {
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
    isMobile: PropTypes.bool,
    user: PropTypes.string,
}
