import PropTypes from 'prop-types'

import { useWindowSize } from '@/hooks/useWindowSize.jsx'
import { Home } from '@/pages'

import ModalHeader from './modalHeader/ModalHeader.jsx'
import { StyledModalRoot } from './styles.js'

const ChatModal = ({ onClose, fullScreen, setFullScreen, isMobile }) => {
    const { height } = useWindowSize()
    const isSmallSize = !isMobile && !fullScreen

    return (
        <StyledModalRoot
            vh={height}
            fullScreen={fullScreen}
            isSmallSize={isSmallSize}
            isMobile={isMobile}
        >
            <ModalHeader
                onClose={onClose}
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                isMobile={isMobile}
            />
            <Home isSmallSize={isSmallSize} />
        </StyledModalRoot>
    )
}

export default ChatModal

ChatModal.propTypes = {
    setUser: PropTypes.func,
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
    isMobile: PropTypes.bool,
}
