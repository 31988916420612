import styled, { css, keyframes } from 'styled-components'

import { MicIcon } from '@/assets/icons/icons.jsx'

export const StyledWrapper = styled.div`
    ${() => css`
        position: relative;
        display: grid;
        grid-template-rows: 1fr 0fr;
        height: 100%;
        overflow: auto;
    `}
`

export const StyledInputWrapper = styled.div`
    ${({ theme, isSmallSize }) => css`
        width: 100%;
        justify-self: center;
        margin: 0px 0px 20px 0px;

        ${theme.mediaQueries.lUp} {
            width: 800px;
            margin: 0px 0px 40px 0px;
        }

        ${isSmallSize &&
        css`
            width: 100% !important;
            margin-bottom: 0px !important;

            & textarea {
                width: 100% !important;
                padding: ${theme.spacing}px 26px ${theme.spacing}px
                    ${theme.spacing}px !important;
                font-size: 14px;
            }
        `}
    `}
`

export const StyledMessagesWrapper = styled.div`
    ${({ theme, isSmallSize }) => css`
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        margin: ${theme.spacing * 2}px;
        overflow: auto;
        padding: 0px 10px;

        ${theme.mediaQueries.mUp} {
            &::-webkit-scrollbar {
                width: 10px;
                background: inherit;
            }

            &::-webkit-scrollbar-thumb {
                background: ${theme.palette.gray.normal};
                border-radius: 10px;
            }
        }

        ${theme.mediaQueries.lUp} {
            margin: 48px 110px;
        }

        ${theme.mediaQueries.xxlUp} {
            margin: 48px 0px;
        }

        margin: 48px 0px;
        ${isSmallSize &&
        css`
            gap: ${theme.spacing * 2}px !important;
            margin: ${theme.spacing * 2}px !important;
        `}
    `}
`

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

export const StyledStopRecordingButton = styled(MicIcon)`
    ${({ theme }) => css`
        fill: ${theme.palette.red};
        animation: ${pulse} 1.5s ease-in-out infinite;
    `}
`

export const StyledStartRecordingButton = styled(MicIcon)`
    ${({ theme, disabled }) => css`
        fill: ${theme.palette.white};

        ${disabled &&
        css`
            fill: ${theme.palette.gray.light};
            cursor: not-allowed;
        `}
    `}
`
