import PropTypes from 'prop-types'
import { useRef } from 'react'

import {
    StyledIcon,
    StyledIcons,
    StyledTextAreaContainer,
    StyledTextAreaInput,
} from './styles'

const TextArea = ({
    id,
    name,
    placeholder,
    onChange,
    onBlur,
    value,
    cols,
    rows,
    icon,
    iconPosition = 'right',
    disabled,
    iconCallback,
    isSmallSize,
    ...rest
}) => {
    const textarea = useRef()
    const textareaDefaultHeight = isSmallSize ? 54 : 62
    const autoResize = () => {
        if (textarea.current) {
            textarea.current.style.height = `${textareaDefaultHeight}px`
            textarea.current.style.height =
                (textarea?.current?.scrollHeight > textareaDefaultHeight
                    ? textarea?.current?.scrollHeight
                    : textareaDefaultHeight) + 'px'
        }
    }

    return (
        <StyledTextAreaContainer>
            <StyledIcons iconPosition={iconPosition}>
                {icon && (
                    <StyledIcon
                        disabled={disabled}
                        {...(iconCallback && { onClick: iconCallback })}
                    >
                        {icon}
                    </StyledIcon>
                )}
            </StyledIcons>
            <StyledTextAreaInput
                ref={textarea}
                name={name}
                id={id}
                cols={cols}
                rows={rows}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                oninput={autoResize()}
                {...rest}
            />
        </StyledTextAreaContainer>
    )
}

export default TextArea

TextArea.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number,
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconCallback: PropTypes.func,
    disabled: PropTypes.bool,
    isSmallSize: PropTypes.bool,
}
