import styled, { css } from 'styled-components'

export const StyledModalRoot = styled.div`
    ${({ theme, vh, isSmallSize, fullScreen, isMobile }) => css`
        background-color: ${theme.palette.gray.mainDark};
        color: ${theme.palette.white};
        display: grid;
        grid-template-rows: auto 1fr;
        padding: 8px;
        overflow: 'hidden';
        zindex: 1033;
        ${fullScreen &&
        css`
            height: calc(${vh}px);
            width: 100vw;
            margin: 0 auto;

            ${theme.mediaQueries.xxlUp} {
                padding: 0;
                max-width: 1024px;
            }
        `};

        ${isMobile &&
        css`
            height: calc(${vh}px);
            width: 100vw;
        `};

        ${isSmallSize &&
        css`
            height: 500px;
            width: 300px;
            border-radius: 30px 0px 0px 30px;
            border: 2px solid;
            border-color: ${theme.palette.gray.light};
            border-right: 0px;
            ${theme.mediaQueries.lUp} {
                height: 600px;
                width: 400px;
                padding: 16px;
            }
        `};
    `}
`
