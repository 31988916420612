import { createGlobalStyle, withTheme } from 'styled-components'

import GilroyBlack from '../font/Gilroy-Black.woff2'
import GilroyBold from '../font/Gilroy-Bold.woff2'
import GilroyRegular from '../font/Gilroy-Regular.woff2'
import GilroySemibold from '../font/Gilroy-Semibold.woff2'

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Gilroy-Regular';
        src: url(${GilroyRegular}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Bold';
        src: url(${GilroyBold}) format('woff2');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Black';
        src: url(${GilroyBlack}) format('woff2');
        font-weight: 900;
        font-style: normal;
    }


    @font-face {
        font-family: 'Gilroy-Semibold';
        src: url(${GilroySemibold}) format('woff2');
        font-weight: 600;
        font-style: normal;
    }
`

export default withTheme(GlobalStyles)
