import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { StereoAudioRecorder } from 'recordrtc'
import RecordRTC from 'recordrtc'
import { useTheme } from 'styled-components'

import { SendIcon } from '@/assets/icons/icons.jsx'
import TextArea from '@/components/textarea/TextArea.jsx'
import Recording from '@/pages/home/chat-input/recordingWave/Recording.jsx'
import { StyledStartRecordingButton } from '@/pages/home/styles.js'
import { checkMediaDeviceSupport } from '@/utilities/helpers.js'

const MAX_RECORDER_SECONDS = 30

const ChatInput = ({
    inputDisabled,
    isMicDisabled,
    messageText,
    setMessageText,
    setError,
    setErrorMessage,
    handleSendMessage,
    isSmallSize,
    sendAudio,
}) => {
    const theme = useTheme()

    const [recorderState, setRecorderState] = useState({
        initRecording: false,
        recordingSeconds: 0,
    })

    const recorderRef = useRef()
    const micRef = useRef(null)
    const isEdge =
        navigator.userAgent.indexOf('Edge') !== -1 &&
        (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)

    // Update time
    useEffect(() => {
        let recordingInterval = null

        if (recorderState.initRecording)
            recordingInterval = setInterval(() => {
                setRecorderState(prevState => ({
                    ...prevState,
                    recordingSeconds: prevState.recordingSeconds + 1,
                }))
            }, 1000)
        else clearInterval(recordingInterval)

        return () => clearInterval(recordingInterval)
    })

    // Auto stop recording
    useEffect(() => {
        if (
            recorderState.initRecording === true &&
            recorderState.recordingSeconds >= MAX_RECORDER_SECONDS
        ) {
            stopRec() // force stop after max duration
        }
    }, [recorderState])

    // Init recorder and start recording
    const onMicHandler = mic => {
        micRef.current = mic
        let options = {
            checkForInactiveTracks: true,
            type: 'audio',
            numberOfAudioChannels: isEdge ? 1 : 2,
            bufferSize: 16384,
            mimeType: 'audio/x-wav',
            recorderType: StereoAudioRecorder,
        }
        // Remove previous instance
        if (recorderRef.current) {
            recorderRef.current.destroy()
            recorderRef.current = null
        }
        recorderRef.current = new RecordRTC(mic, options)
        recorderRef.current.startRecording()
        setRecorderState(prev => ({
            ...prev,
            initRecording: true,
        }))
        console.log('started rec')
    }

    const captureMic = async () => {
        if (micRef.current) {
            onMicHandler(micRef.current)
            return
        }
        try {
            // Check
            if (checkMediaDeviceSupport()) {
                setErrorMessage(
                    'This browser does not supports WebRTC getUserMedia API.'
                )
                if (navigator.getUserMedia) {
                    setError(true)
                    setErrorMessage(
                        'This browser seems supporting deprecated getUserMedia API.'
                    )
                }
            }
            const mic = await navigator.mediaDevices.getUserMedia({
                audio: isEdge
                    ? true
                    : {
                          echoCancellation: false,
                      },
            })
            console.log('captureMIC:success')
            onMicHandler(mic)
        } catch (e) {
            setError(true)
            setErrorMessage(
                'Please allow access to your microphone to record your voice'
            )
        }
    }

    const saveBlob = () => {
        const blob = recorderRef.current.getBlob()
        if (blob) {
            sendAudio(blob)
        } else {
            setError(true)
        }
    }

    const stopRec = async () => {
        recorderRef.current.stopRecording(saveBlob)
        if (micRef.current) {
            micRef.current.getTracks().forEach(track => track.stop())
            micRef.current.stop()
            micRef.current = null
        }
        setRecorderState(prev => ({
            ...prev,
            initRecording: false,
            recordingMinutes: 0,
            recordingSeconds: 0,
        }))
    }

    if (recorderState.initRecording) {
        return (
            <Recording
                recorderState={recorderState}
                onClick={recorderState.initRecording ? stopRec : captureMic}
            />
        )
    } else {
        return (
            <>
                <TextArea
                    placeholder={'Send a message'}
                    onChange={e => setMessageText(e.target.value)}
                    iconPosition={'right'}
                    icon={
                        messageText ? (
                            <SendIcon fill={theme.palette.white} />
                        ) : (
                            <StyledStartRecordingButton
                                disabled={isMicDisabled}
                                onClick={
                                    recorderState.initRecording
                                        ? stopRec
                                        : captureMic
                                }
                            />
                        )
                    }
                    value={messageText}
                    iconCallback={e => {
                        if (!inputDisabled) {
                            handleSendMessage(e, messageText)
                        }
                    }}
                    disabled={inputDisabled}
                    onKeyUp={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        if (
                            event.code === 'Enter' &&
                            !event.shiftKey &&
                            !inputDisabled
                        ) {
                            handleSendMessage(event, messageText)
                        }
                    }}
                    onKeyDown={event => {
                        if (event.code === 'Enter' && !event.shiftKey) {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                    }}
                    rows={1}
                    isSmallSize={isSmallSize}
                />
            </>
        )
    }
}

export default ChatInput

ChatInput.propTypes = {
    inputDisabled: PropTypes.bool,
    isMicDisabled: PropTypes.bool,
    messageText: PropTypes.string,
    setMessageText: PropTypes.func,
    setIsLoading: PropTypes.func,
    setError: PropTypes.func,
    setErrorMessage: PropTypes.func,
    handleSendMessage: PropTypes.func,
    isSmallSize: PropTypes.bool,
    sendAudio: PropTypes.func,
}
