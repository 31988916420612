import originalAxios from 'axios'

import { AUDIO_TYPE } from '@/utilities/helpers.js'

import config from '../../../config.js'
import axios from '../axiosInstance.js'

export const httpSendMessage = (data, max_tokens = 1000, cancelToken) => {
    const params = {
        data,
        max_tokens,
    }
    return axios.post(
        `https://models.aixplain.com/api/v1/execute/${config.env.VITE_AIXPLAIN_CHAT_MODEL_ID}`,
        params,
        { cancelToken }
    )
}
export const httpSendAudio = data => {
    return axios.post(
        `https://models.aixplain.com/api/v1/execute/${config.env.VITE_AIXPLAIN_SPEECH_TO_TEXT_MODEL_ID}`,
        {
            data,
        }
    )
}

export const httpGetAudioFromText = data => {
    return axios.post(
        `https://models.aixplain.com/api/v1/execute/${config.env.VITE_AIXPLAIN_TEXT_TO_SPEECH_MODEL_ID}`,
        {
            data: data,
        }
    )
}

export const httpGetPresignedUrl = fileName => {
    return axios.post(
        `https://platform-api.aixplain.com/sdk/file/upload/temp-url`,
        {
            contentType: AUDIO_TYPE,
            originalName: fileName,
        }
    )
}

export const httpUploadFileToS3 = (presignedUrl, formData, options) => {
    return originalAxios.put(presignedUrl, formData, options)
}
