import styled, { css, keyframes } from 'styled-components'

const bubbleOnCenter = keyframes`
    from {
        transform: translateY(100%) scale(0);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
`

const shrinkAndMoveUp = keyframes`
    0% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(-50px);
    }
`

const quiet = keyframes`
    25% {
        transform: scaleY(0.6);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(0.8);
    }
`

const normal = keyframes`
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(0.6);
    }
`

const loud = keyframes`
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.4);
    }
    75% {
        transform: scaleY(1.2);
    }
`

const morphing = keyframes`
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    }
    12.5% {
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    }
    25% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
        box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
    }
    37.5% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
    }
    50% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    }
    62.5% {
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    }
    75% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
        box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
    }
    87.5% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
    }
    100% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    }
`

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const StyledContainerRecord = styled.div`
    ${({ theme }) => css`
        padding: 12px 20px 12px 20px;
        height: ${theme.spacing * 8 - 2}px;
        color: ${theme.palette.white};
        background-color: ${theme.palette.gray.dark};
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `};
`

export const StyledContainerRound = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 200px;
        height: 200px;
        background: ${theme.palette.white};
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: ${shrinkAndMoveUp} 2.5s ease-in-out forwards,
            ${morphing} 4s ease-in-out forwards infinite;
    `};
`

export const StyledOverlay = styled.div`
    ${({ theme }) => css`
        position: absolute;
        width: 100%;
        background-color: ${theme.palette.gray.mainDarkTransparent};
        height: 100%;
        z-index: 1;
        opacity: 0;
        animation: ${fadeIn} 1s ease-in-out forwards;
    `}
`

export const StyledContainer = styled.div`
    ${({ isMobile, appear }) => css`
        position: ${isMobile ? 'fixed' : 'absolute'};
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        ${appear &&
        css`
            animation: ${bubbleOnCenter} 0.7s ease-in-out forwards;
        `}
    `};
`

export const StyledWaveContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 30px;
    width: 60px;
    margin: 0 auto;
`
export const StyledWave = styled.div`
    width: 10px;
    height: 30px;
    border-radius: 10px;
    margin: 0 5px;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
`

export const Wave1 = styled(StyledWave)`
    ${({ theme }) => css`
        animation-name: ${quiet};
        background-color: ${theme.palette.green};
    `}
`

export const Wave2 = styled(StyledWave)`
    ${({ theme }) => css`
        animation-name: ${normal};
        background-color: ${theme.palette.blue};
    `}
`

export const Wave3 = styled(StyledWave)`
    ${({ theme }) => css`
        animation-name: ${quiet};
        background-color: ${theme.palette.violet};
    `}
`

export const Wave4 = styled(StyledWave)`
    ${({ theme }) => css`
        animation-name: ${loud};
        background-color: ${theme.palette.pink};
    `}
`
export const Wave5 = styled(StyledWave)`
    ${({ theme }) => css`
        animation-name: ${quiet};
        background-color: ${theme.palette.orange};
    `}
`
