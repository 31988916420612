import styled, { css } from 'styled-components'

export const StyledHeaderWrapper = styled.header`
    ${({ theme }) => css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 80px;
        background-color: ${theme.palette.gray.mainDark};
        border-bottom: 1px solid ${theme.palette.gray.main};
    `}
`

export const StyledHeaderRoot = styled.header`
    ${({ theme }) => css`
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: auto auto;
        padding: 0 20px;
        height: 100%;
        ${theme.mediaQueries.lUp} {
            padding: 0 120px;
        }
        ${theme.mediaQueries.xxlUp} {
            padding: 0;
            margin: 0 auto;
            max-width: 1024px;
        }
    `}
`

export const StyledHeaderLogo = styled.div`
    ${({ theme, hasUser }) => css`
        display: flex;
        gap: ${theme.spacing}px;
        justify-self: ${hasUser ? 'start' : 'center'};
        & svg {
            height: 25px;
            width: auto;
        }
        ${theme.mediaQueries.mUp} {
            & svg {
                height: 30px;
            }
        }
    `}
`
export const StyledHeaderInfo = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing * 2}px;
        & svg {
            cursor: pointer;
        }
    `}
`
