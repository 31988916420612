import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import {
    ErrorMessage,
    LoadingMessage,
    RegularMessage,
    TemplateMessage,
} from '@/pages/home/messages/Messages.jsx'
import { MESSAGE_TEMPLATES, ROLE_USER } from '@/utilities/constants/index.js'
import { getRandomArbitrary, scrollToCb } from '@/utilities/helpers.js'

const ChatBody = ({
    messages,
    isLoading,
    isSmallSize,
    handleSendMessage,
    error,
    errorMessage,
    messagesWrapper,
    isLoadingSendAudio,
    setAudio,
    setAudioPlaying,
    audioPlaying,
}) => {
    const theme = useTheme()
    const [randomColor, setRandomColor] = useState(theme.palette.green)
    const colors = [
        theme.palette.green,
        theme.palette.blue,
        theme.palette.violet,
        theme.palette.pink,
        theme.palette.orange,
    ]

    useEffect(() => {
        setRandomColor(colors[getRandomArbitrary(0, colors.length)])
    }, [])

    return (
        <>
            {messages.length ? (
                <>
                    {messages.map((message, index) => {
                        return (
                            <RegularMessage
                                key={index}
                                isMine={message.role === ROLE_USER}
                                color={randomColor}
                                text={message.content}
                                scrollToCb={() => scrollToCb(messagesWrapper)}
                                isSmallSize={isSmallSize}
                                audio={message.audio}
                                setAudio={setAudio}
                                setAudioPlaying={setAudioPlaying}
                                audioPlaying={audioPlaying}
                            />
                        )
                    })}

                    {error && (
                        <ErrorMessage
                            isSmallSize={isSmallSize}
                            message={errorMessage}
                        />
                    )}
                    {isLoadingSendAudio && (
                        <LoadingMessage
                            isMine
                            color={randomColor}
                            scrollToCb={() => scrollToCb(messagesWrapper)}
                            isSmallSize={isSmallSize}
                        />
                    )}
                    {isLoading && (
                        <LoadingMessage
                            isMine={false}
                            color={randomColor}
                            scrollToCb={() => scrollToCb(messagesWrapper)}
                            isSmallSize={isSmallSize}
                        />
                    )}
                </>
            ) : (
                <>
                    {isLoadingSendAudio ? (
                        <LoadingMessage
                            isMine
                            color={randomColor}
                            scrollToCb={() => scrollToCb(messagesWrapper)}
                            isSmallSize={isSmallSize}
                        />
                    ) : (
                        <>
                            {error ? (
                                <ErrorMessage
                                    isSmallSize={isSmallSize}
                                    message={errorMessage}
                                />
                            ) : (
                                MESSAGE_TEMPLATES.map((message, index) => {
                                    return (
                                        <TemplateMessage
                                            key={index}
                                            color={colors[index]}
                                            itemCallback={e => {
                                                setRandomColor(colors[index])
                                                handleSendMessage(
                                                    e,
                                                    message.message
                                                )
                                            }}
                                            title={message.title}
                                            text={message.text}
                                            isSmallSize={isSmallSize}
                                        />
                                    )
                                })
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default ChatBody

ChatBody.propTypes = {
    messages: PropTypes.array,
    isLoading: PropTypes.bool,
    isSmallSize: PropTypes.bool,
    handleSendMessage: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    messagesWrapper: PropTypes.object,
    isLoadingSendAudio: PropTypes.bool,
    setAudio: PropTypes.func,
    setAudioPlaying: PropTypes.func,
    audioPlaying: PropTypes.bool,
}
