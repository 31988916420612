import {
    httpGetAudioFromText,
    httpGetPresignedUrl,
    httpSendAudio,
    httpUploadFileToS3,
} from '@/services/http-requests/chat.http.js'

import axios from '../services/axiosInstance.js'

export const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
}

export const scrollToCb = ref => {
    if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight
    }
}

export const getTextBlocksWithCode = displayText => {
    let resultArray = []
    let isCode = false
    let codeText = ''

    for (let i = 0; i < displayText.length; i++) {
        if (
            displayText[i] === '`' &&
            displayText[i + 1] === '`' &&
            displayText[i + 2] === '`'
        ) {
            if (isCode) {
                resultArray.push({ text: codeText, isCode: true })
                codeText = ''
            } else {
                resultArray.push({ text: codeText, isCode: false })
                codeText = ''
            }
            isCode = !isCode
            i += 2
        } else {
            codeText += displayText[i]
        }
    }

    if (codeText) {
        resultArray.push({ text: codeText, isCode: isCode })
    }

    return resultArray
}

export const copyTextToClipboard = text => {
    var textArea = document.createElement('textarea')
    textArea.value = text

    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        document.execCommand('copy')
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
    }
    document.body.removeChild(textArea)
}

export const convertAudioFileToUrl = async file => {
    const { data: presignedData } = await httpGetPresignedUrl('message.wav')
    const options = {
        headers: {
            'Content-Type': AUDIO_TYPE,
        },
    }
    let formData = new FormData()
    formData.append('file', file)
    await httpUploadFileToS3(presignedData?.uploadUrl, file, options)

    const bucketNameMatch = presignedData?.uploadUrl.match(
        /https:\/\/(.*?).s3.amazonaws.com/
    )
    if (bucketNameMatch && bucketNameMatch.length > 1) {
        const bucketName = bucketNameMatch[1]
        return `https://${bucketName}.s3.amazonaws.com/${presignedData?.key}`
    } else {
        throw new Error('Failed to extract bucket name from pre-signed URL.')
    }
}

export const convertAudioUrlToText = async audioUrl => {
    try {
        const { data } = await httpSendAudio(audioUrl)
        const url = data.data
        return await handlePolling(url)
    } catch (error) {
        console.log('convertAudioUrlToText error', error)
    }
}

export const convertTextToAudio = async text => {
    try {
        const { data } = await httpGetAudioFromText(text)
        const url = data.data
        return await handlePolling(url)
    } catch (error) {
        console.log(error)
    }
}

export const handlePolling = async (
    urlToPoll,
    pollingInterval = 1000,
    maxPollingAttempts = 100
) => {
    let pollingAttempts = 0
    if (!urlToPoll) return Promise.reject('url not provided')

    return new Promise((resolve, reject) => {
        const poll = setInterval(async () => {
            try {
                const { data } = await axios.get(urlToPoll)
                pollingAttempts++

                if (data.completed) {
                    clearInterval(poll)
                    resolve(data.data)
                } else {
                    if (pollingAttempts > maxPollingAttempts) {
                        clearInterval(poll)
                        reject('Timeout')
                    }
                }
            } catch (error) {
                clearInterval(poll)
                console.error('Error during polling', error)
                reject(error)
            }
        }, pollingInterval)
    })
}

export const convertStringIntoAudio = audioString => {
    if (!audioString) return
    try {
        return new Audio(audioString)
    } catch (error) {
        console.log('error', error)
    }
}

export const checkMediaDeviceSupport = () => {
    return (
        typeof navigator.mediaDevices === `undefined` ||
        !navigator.mediaDevices.getUserMedia
    )
}

export const AUDIO_TYPE = 'audio/x-wav'
