import PropTypes from 'prop-types'
import {
    createContext,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from 'react'

export const AppContext = createContext({})

const SET_RESET_CHAT_ACTION = 'SET_RESET_CHAT'

const appReducer = (state, action) => {
    switch (action.type) {
        case SET_RESET_CHAT_ACTION:
            return {
                ...state,
                isChatReset: action.payload,
            }
        default:
            return state
    }
}

const appInitialState = {
    isChatReset: false,
}

const AppProvider = ({ children }) => {
    const [app, dispatch] = useReducer(appReducer, appInitialState)

    const resetChatAction = useCallback(payload => {
        dispatch({ type: SET_RESET_CHAT_ACTION, payload })
    }, [])

    const [value, setValue] = useState({
        resetChatAction,
    })

    useEffect(() => {
        setValue(prev => ({ ...prev, ...app }))
    }, [app])

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppProvider

AppProvider.propTypes = {
    children: PropTypes.array,
}
